import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogModel
} from '../utils/confirmation-dialog/confirmation-dialog.component';
import {AbstractChildItem} from '../../models/abstract_child_item';
import {BikeCRMApiAbstract} from '../../services/bikecrm-api-base';
import {AbstractControl, ValidationErrors, Validators} from '@angular/forms';
import {AbstractBaseItem} from '../../models/abstract_base_api_item';
import {BarcodeScannerAbstractComponent} from '../abstract-barcode-scanner/abstract-barcode-scanner';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {UsersService} from '../../services/users.service';

export enum ChildItemFieldTypes {
  text,
  currency,
  number,
  picture,
  date,
  boolean,
  action,
  foreign_key,
  calculated,
  percent,
  integer
}

export class ChildItemFieldMetadata {
  hidden: boolean;
  i18nKey: string;
  i18nKeyAddAction: string;
  type: ChildItemFieldTypes;
  defaultValue: any;
  validators: [((control: AbstractControl) => (ValidationErrors | null))];
  collapsible: boolean;
  showAsColumn: boolean;
  readonly: boolean;

  constructor(i18nKey: string,
              i18nKeyAddAction: string,
              type: ChildItemFieldTypes,
              defaultValue?: any,
              showAsColumn?: boolean,
              validators?: [((control: AbstractControl) => (ValidationErrors | null))],
              collapsible?: boolean,
              hidden?: boolean,
              readonly ?: boolean,
              ) {
    this.i18nKey = i18nKey;
    this.i18nKeyAddAction = i18nKeyAddAction;
    this.type = type;
    this.defaultValue = (defaultValue != null) ? defaultValue : null;
    this.validators = validators ? validators : null;
    this.collapsible = (collapsible != null) ? collapsible : true;
    this.showAsColumn = showAsColumn ? showAsColumn : false;
    this.hidden = hidden ? hidden : false;
    this.readonly = readonly ? readonly : false;
  }
}

export interface IChildItemList {
  // fields: ChildItemFieldMetadata[];
  fields: object; // TODO: improve type hint;
  isEditing: boolean;

  addItemStart(): void;

  addItemEnd(): void;

  addItem(): void;

  saveItem(item: any): void;

  getInputTypeCalculated(field: string): string;
  getCalculatedField(item: any, field: string): any;
  setCalculatedField(item: any, modifiedText: string, field: string): void;
}


export class FieldItem {
  field: string;
  i18nKey: string;
  i18nKeyAddAction: string;
  type: string; // qty, price, text
}


@Component({
  template: '',
  // TODO: Document
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export abstract class ChildItemEditableListAbstractComponent<T extends AbstractChildItem<any>> extends BarcodeScannerAbstractComponent
  implements IChildItemList, OnInit, OnDestroy, OnChanges {
  // Options for inline edit:
  // https://stackblitz.com/edit/angular-g5u7cy?file=app%2Ftable-editing-example.html
  // https://stackblitz.com/edit/inline-edit-mat-table?file=app%2Fapp.component.html
  // https://stackblitz.com/edit/material2-beta12-es19ub?file=app%2Fapp.component.html
  // https://plnkr.co/edit/wL23b5C7bggjKPgo66rk?p=preview&preview
  // another possible solution for inline edit may be "popover edit" I think it's still experimental

  // Framework-agnostic information: https://material.io/components/data-tables

  // TODO: check that there is only one MainInput on fields, otherwise raise exception

  // TODO: test what happens if you delete a item and it fails, we show the item back on list? same with create/edit, etc
  //        in the future, we should add two buttons if this happens, retry and delete, or rollback or something like this

  @Input() items: T[];
  @Input() parent: any; // TODO: improve type hint
  @Output() itemsChange = new EventEmitter<T[]>();  // just one item changed

  // editList is a list of editable items, viewList is meant for simple readonly lists, without totals, aggregates or
  //   anything
  @Input() mode = 'editList'; // editList, viewList

  showFooterAddOption = true;

  abstract addItemLiteralI18n: string;
  protected onDestroy$: Subject<void> = new Subject<void>();

  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  public dataSource = new MatTableDataSource();

  // Maybe rename to parentApiFieldName?
  abstract parentApiRelName: string;
  parentApiContentTypeName: string;

  abstract fields: object;

  isEditing = false;

  currencyCode: string;

  expandedFields: { [key: string]: Set<string> } = {};

  protected constructor(
    public dialog: MatDialog,
    protected translate: TranslateService,
    protected notificationService: CrmNotificationsService,
    protected itemApiService: BikeCRMApiAbstract,
    protected nativeInterfacesService: NativeInterfacesService,
    protected usersService: UsersService
  ) {
    super(nativeInterfacesService, dialog);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.currencyCode = this.usersService.business.currency.toUpperCase();
    if (!this.parent) {
      throw new Error('You should set parent');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.data = this.items;
  }

  expandField(t: T, field: string): void {
    if (!(field in this.expandedFields)) {
      this.expandedFields[field] = new Set();
    }
    this.expandedFields[field].add(t.id);
  }

  isFieldExpanded(t: T, field: string): boolean {
    if (!(field in this.expandedFields)) {
      this.expandedFields[field] = new Set();
    }
    return (this.isOptionalFieldFilled(t, field) || this.expandedFields[field].has(t.id));
  }

  isOptionalFieldFilled(t: T, field: string): boolean {
    return t[field];
  }

  hasAnyFieldExpanded(t: T): boolean {
    for (const fieldItem of this.fieldsCollapsible) {
      if (this.isFieldExpanded(t, fieldItem)) {
        return true;
      }
    }
    return false;
  }

  getInputTypeCalculated(field: string): string {
    throw Error('if the class has any calculated fields you should override getInputTypeCalculated, getCalculatedField and setCalculatedField');
  }

  getCalculatedField(item: any, field: string): any {
    throw Error('if the class has any calculated fields you should override getCalculatedField, getCalculatedField and setCalculatedField');
  }
  getCalculatedFieldDisplay(item: any, field: string): any {
    // special method that only gets called on display, not in inputs
    return this.getCalculatedField(item, field);
  }

  setCalculatedField(item: any, modifiedText: string, field: string): void {
    throw Error('if the class has any calculated fields you should override setCalculatedField, getCalculatedField and setCalculatedField');
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onDestroy$.next();
  }

  addItemStart(item?: T): void {
    this.addItem(item, true);
  }

  addItemEnd(item?: T): void {
    this.addItem(item, false);
  }

  abstract createNewItemInstance(): T;

  addItem(item?: T, start: boolean = true): void {
    if (item) {
      this.saveItem(item);
    } else {
      item = this.createNewItemInstance();
    }
    if (start) {
      this.items?.unshift(item);
    } else {
      this.items?.push(item);
    }
    this.dataSource.data = this.items;
    this.itemsChange.emit(this.items);
  }

  _updateItems(item: T): void {
    // TODO: doesn't work with new items
    this.items = this.items.map(obj => item.id === obj.id ? item : obj);
    this.dataSource.data = this.items;

    this.itemsChange.emit(this.items);
  }

  _removeItem(itemId: string): void {
    this.items = this.items.filter(obj => obj.id !== itemId);
    this.dataSource.data = this.items;
    this.itemsChange.emit(this.items);
  }

  public get childItemFieldTypes(): typeof ChildItemFieldTypes {
    return ChildItemFieldTypes;
  }

  public get displayedColumns(): string[] {
    // console.log(Object.keys(this.fields).filter(k => this.fields[k].metadata.showAsColumn && !this.fields[k].metadata.hidden));
    return Object.keys(this.fields).filter(k => this.fields[k].metadata.showAsColumn && !this.fields[k].metadata.hidden);
  }

  public fieldMetaData(field: string): ChildItemFieldMetadata {
    return this.fields[field].metadata;
  }

  public get fieldsApi(): string[] {
    //  Fields that should be sent to the api
    // return this.fields.filter(f2 => (f2.type !== ChildItemFieldTypes.calculated));
    // return Object.keys(this.fields).filter(k => this.fields[k].metadata.type !== ChildItemFieldTypes.calculated);
    // TODO: maybe we can add a calculatedApi type? for example vat is calculated but we need to send it to the api
    //  but other calculated fields like totalCost we don't need to send them to the api, for now this should work
    //  as api will ignore unknown fields
    return Object.keys(this.fields);
  }

  public get fieldsApiMandatory(): string[] {
    // tslint:disable-next-line:max-line-length
    return this.fieldsApi.filter(k => (this.fields[k].metadata.validators != null && this.fields[k].metadata.validators.includes(Validators.required)));
  }

  public get fieldsApiOptional(): string[] {
    // tslint:disable-next-line:max-line-length
    return this.fieldsApi.filter(k => (this.fields[k].metadata.validators == null || !this.fields[k].metadata.validators.includes(Validators.required)));
  }

  public get fieldsCollapsible(): string[] {
    // TODO: Cache results? this is called often from the template
    // console.log(Object.keys(this.fields).filter(k => this.fields[k].metadata.collapsible));
    return Object.keys(this.fields).filter(k => this.fields[k].metadata.collapsible);
  }

  isItemSyncing(item: T): boolean {
    return AbstractBaseItem.isSyncing(item);
  }

  ensurePercent(val: number): number {
    // TODO: check higher than 100 or lower than 0, and what happens with 1? (can be 100% or 1%)
    if (val > 1 && val < 100) {
      val = val / 100;
    }
    return val;
  }

  saveItem(item: T): void {
    const formData = new FormData();
    formData.append(this.parentApiRelName, this.parent.id);

    if (this.parentApiContentTypeName != null) {
      formData.append('contentType', this.parentApiContentTypeName);
    }

    for (const field of this.fieldsApiMandatory) {
      let val = item[field];

      if (typeof val === 'string') {
        val = val.trim();
      }

      if (this.fieldMetaData(field).type === ChildItemFieldTypes.percent) {
        val = this.ensurePercent(Number(val));
      }
      if (val == null || val === '') {
        val = this.fieldMetaData(field).defaultValue;
      }
      if (val == null || val === '') {
        throw new Error('Mandatory field ' + field + ' is null');
      }
      formData.append(field, val);
    }

    for (const field of this.fieldsApiOptional) {
      // https://stackoverflow.com/a/39056998/888245
      if (item[field] == null) {
        // If field is empty we set it to default value. But if it's a foreign key we just don't send this field to the API
        if (this.fieldMetaData(field).type !== ChildItemFieldTypes.foreign_key) {
          item[field] = this.fieldMetaData(field).defaultValue;
          formData.append(field, item[field]);
        }
      } else {

        let val = item[field];
        if (this.fieldMetaData(field).type === ChildItemFieldTypes.percent) {
          val = this.ensurePercent(Number(val));
        }
        formData.append(field, val);
      }
    }

    let s: Observable<T>;
    if (this._isDraft(item)) {
      s = this.itemApiService.create(formData).pipe(takeUntil(this.onDestroy$));
    } else {
      s = this.itemApiService.modify(item.id, formData).pipe(takeUntil(this.onDestroy$));
    }

    item._backendSynced = false;

    s.subscribe(
      r => {
        if (this._isDraft(item)) {
          // Assign ID to the old item, so we can map it with the new data on _updateTasks()
          // For now we force to start a item with a description, so this should work
          // TODO: test:
          this.items[this.items.findIndex(obj => item.id === obj.id)] = r;
        }
        this._updateItems(r);
      },
      async err => {
        if (this._isDraft(item)) {
          await this.deleteItem(item);
        } else {
          // TODO: before enabling this line we should make sure the user is aware of the error, and we should show a message
          //  maybe prevent user invalid inputs before doing this (for example 20.002 makes an error when working with prices)
          // item._backendSynced = true;
        }
        this.notificationService.error(
          await this.translate.get('FEEDBACK_MESSAGES.CHECK_INTERNET_CONNECTION').toPromise(),
          await this.translate.get('FEEDBACK_MESSAGES.PROBLEM_SAVING_TASK').toPromise()
        );
      },
      () => {
        this.notificationService.debug('item successfully modified');
      },
    );
  }

  async deleteItem(item: T): Promise<void> {
    if (this._isDraft(item)) {
      this._removeItem(this._getID(item));
      return;
    }

    const deleteLiteral = await this.translate.get('DELETE', {itemName: AbstractBaseItem.str(item)}).toPromise();
    const message = await this.translate.get('QUESTIONS.CONFIRM_DELETE_ITEM', {itemName: AbstractBaseItem.str(item)}).toPromise();
    const cancelLiteral = await this.translate.get('CANCEL').toPromise();

    const dialogData = new ConfirmationDialogModel(message, null, deleteLiteral, 'delete', 'warn', cancelLiteral, '');

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (confirmDialog: boolean) => {
        if (confirmDialog) {
          // TODO: delete currant item setting id to "_deleted_" and making an ngif on the parent?
          // raise exception on ng on init if we ever try to build a deleted component
          await this.itemApiService.delete(item.id).toPromise();
          // this.itemDeleted.emit(item.id);  // explore 2way binding better
          this._removeItem(this._getID(item));
        } else {
          // cancelled
        }
      });
  }

  _isDraft(item: T): boolean {
    return this._getID(item).startsWith('draft_');
  }

  _getID(item: T): string {
    // TODO: maybe better with constructor or getter/setter?
    if (!item.id) {
      item.id = 'draft_' + String(Math.floor(Date.now() / 1000)) + String(Math.floor(Math.random() * 100000000));
    }
    return item.id;
  }

  onEditStatusChange(s: boolean, item: T = null, colDef = null): void {
    this.isEditing = s;
  }

  onInputChange(event: string, orderItem: any, field: string): void {
    console.log('onInputChange called, should be overridden');
  }

  cleanInputToString(input: any, currency = false): string {
    //  Move to a service?

    input = input.toString();
    input = input.trim();
    if (currency) {
      input = input.replaceAll(',', '.');
      input = input.replaceAll(`'`, '.');
    }
    return input;
  }

  closedEdit(item: T, modifiedText: string, modifiedField: string): void {
    if (modifiedText == null) {
      return;
    }

    // TODO: improve, and make if the field is currency type, not searching by field name
    if (modifiedField.toLowerCase().includes('cost')) {
      modifiedText = this.cleanInputToString(modifiedText, true);
    } else {
      modifiedText = this.cleanInputToString(modifiedText, false);

    }
    item[modifiedField] = modifiedText;
    this.saveItem(item);
  }

  allowEditList(): boolean {
    if (this.parent.hasOwnProperty('closed')) {
      return !this.parent.closed;
    }
    return true;
  }
}
