<div class="base-paddings">
  <h2>{{ "BIKE_LIST" | translate }}</h2>

  <div fxLayout="row" fxLayoutAlign="space-between" class="py1">
    <form [formGroup]="filtersFormGroup" fxFlex="45">
      <mat-form-field fxFill appearance="outline">
        <input matInput #searchbox type="text" (keyup)="searchInput = searchbox.value" formControlName="search"
               autocomplete="off"
               placeholder="{{ 'SEARCH' | translate }}">
      </mat-form-field>
    </form>
    <span *ngIf="user">
        <button mat-stroked-button color="primary" [routerLink]="['/bikes/create', {ownerId: user.id}]">
            <mat-icon>add</mat-icon>
          {{ 'ADD_BIKE' | translate }}
        </button>
    </span>
  </div>
  <!-- TODO: Should be 400px height if user is not none, and 430px if it's none-->
  <mat-grid-list [cols]="cols" rowHeight="320px" gutterSize="5px">
    <mat-grid-tile *ngFor="let bike of bikes | filterList:searchInput">
      <app-bike-card [bike]="bike" cardstyle="gridable"></app-bike-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
