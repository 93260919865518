<!--  https://fullcalendar.io/docs/angular  -->

<div fxLayout="row">
  <div fxFlex="10" #bssUndated style="height: 90vh; overflow: scroll;">
    <h4>{{ "BSS_WITHUOT_DATE" | translate }}: {{ bssUndatedList?.length }}</h4>

    <div *ngFor="let bss of bssUndatedList"
         data-event='{ "title": "{{bss.bikeName}}",
                        "id": "{{bss.id}}",
                        "shortPrettyId": "{{bss.shortPrettyId}}",
                        "icon": "TODO",
                        "statusI18n": "TODO",
                        "ownerName": "{{bss.ownerName}}",
                        "generalNotesPrivate": "{{ getGeneralNotesPrivate(bss.getGeneralNotesPrivate) }}",
                        "objectType": "serviceSheet",
                        "eventType": "estimatedDeliveryDt"
                      }'
         style="height: 80px; margin-bottom: 5px; cursor: move;"
         class='bss-undated fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event'>
      <span class='fc-event-main'>{{ bss.bikeName }}</span><br/>
      <span class='fc-event-main'>{{ bss.ownerName }}</span>
    </div>
  </div>
  <div fxFlex fxLayout="column" fxLayoutGap="10px" class="base-paddings" style="height: 90vh;">

    <mat-slide-toggle fxLayoutAlign="end" [(ngModel)]="hideClosed" (ngModelChange)="onHideClosedChange($event)">{{ "HIDE_FINISHED" | translate }}</mat-slide-toggle>

    <full-calendar #calendar [options]="calendarOptions">
      <ng-template #eventContent let-arg>
        <div fxLayout="column">
          <span><mat-icon>{{ arg.event.extendedProps.icon }}</mat-icon> <small>{{ arg.event.extendedProps.statusI18n | translate }}</small></span>
          <b>{{ arg.event.title }}</b>

          <small>{{ arg.event.extendedProps.ownerName }}</small>

          <small>{{ arg.event.extendedProps.shortPrettyId }}</small>
          <small>{{ getGeneralNotesPrivate(arg.event.extendedProps.generalNotesPrivate) }}</small>
        </div>
      </ng-template>
    </full-calendar>

  </div>
</div>
