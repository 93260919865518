import {Component, Input, OnInit} from '@angular/core';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Bike} from 'src/app/models/bike';
import {User} from 'src/app/models/user';
import {BikesService} from 'src/app/services/bikes.service';
import {TitleService} from 'src/app/services/title.service';

@Component({
  selector: 'app-bikes-list',
  templateUrl: './bikes-list.component.html',
  styleUrls: ['./bikes-list.component.scss']
})
export class BikesListComponent implements OnInit {

  @Input() user?: User;

  // TODO: remove owner, is deprecated
  get owner(): User {
    return this.user;
  }

  @Input() set owner(user: User) {
    this.user = user;
  }

  bikes: Bike[];
  cols = 2;

  filtersFormGroup: UntypedFormGroup;
  searchInput: string;

  constructor(
    private titleService: TitleService,
    public media$: MediaObserver,
    private bikesService: BikesService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.titleService.setTitleTranslated('BIKE_LIST');

    this.media$.asObservable().subscribe(() => {
      let colsCandidate = 1;
      if (this.media$.isActive('gt-sm')) {
        colsCandidate = 2;
      }
      if (this.media$.isActive('gt-md')) {
        colsCandidate = 3;
      }
      this.cols = colsCandidate;
    });

    this.filtersFormGroup = this.formBuilder.group({ search: [''] });

    if (this.user) {
      this.bikesService.getOwned(this.user.id).subscribe(x => this.bikes = x);
    } else {
      this.bikesService.getAll().subscribe(x => this.bikes = x);
    }
  }

  getBikePictureUrl(bike: Bike): string {
    // TODO: can I move this to the model?
    return Bike.getPictureUrl(bike);
  }

}
