import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from 'src/app/services/title.service';
import {
  DisplayedColumnMetaData,
  DisplayedColumnTypes,
  ItemSimpleFiltrableListAbstractComponent
} from '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {CrmNotificationsService} from '../../services/crm-notifications.service';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {UsersService} from '../../services/users.service';
import {PrivacyModeService} from '../../services/privacy-mode.service';
import {PosCategoryProduct} from '../../models/pos_category_product';
import {AccountingIncomeInvoicesService} from '../../services/accounting-income-invoices.service';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-accounting-income-invoices-list',
  templateUrl: '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.html',
  styleUrls: ['../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.scss']
})
export class AccountingIncomeInvoicesListComponent extends ItemSimpleFiltrableListAbstractComponent<PosCategoryProduct>
  implements OnInit, OnDestroy, AfterViewInit {

  pageTitleI18N = 'INVOICES';

  displayedColumnsMetaData = {
    printHTML: new DisplayedColumnMetaData('', DisplayedColumnTypes.simple_html),
    invoiceNumber: new DisplayedColumnMetaData('NUMBER', DisplayedColumnTypes.anchor),
    typeHTML: new DisplayedColumnMetaData('TYPE', DisplayedColumnTypes.simple_html),
    createdAt: new DisplayedColumnMetaData('DATE', DisplayedColumnTypes.date),
    totalAmountWithTax: new DisplayedColumnMetaData('TOTAL', DisplayedColumnTypes.currency),
  };


  // primaryFab = new Fab('NEW_CATEGORY', 'add', 'fab_add_new_category', FabTypes.singleAction);
  primaryFab = null;
  secondaryFab = null;

  apiFilters = {};

  workshopi18n = '';
  storei18n = '';

  constructor(
    @Optional() public dialogRef: MatDialogRef<AccountingIncomeInvoicesListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string },
    protected router: Router,
    protected titleService: TitleService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected accountingIncomeInvoicesService: AccountingIncomeInvoicesService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected dialog: MatDialog,
    protected notificationService: CrmNotificationsService,
    protected media$: MediaObserver,
    protected usersService: UsersService,
    protected privacyModeService: PrivacyModeService,
    private sanitized: DomSanitizer,
    private translate: TranslateService
  ) {
    super(
      dialogRef,
      data,
      router,
      titleService,
      activatedRoute,
      formBuilder,
      accountingIncomeInvoicesService,
      nativeInterfacesService,
      dialog,
      media$,
      usersService,
      privacyModeService
    );
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    // TODO: move to abstract class:
    if (this.dialogRef != null) {
      if (this.data.mode === 'floating-selector') {
      }
    }

    this.workshopi18n = await this.translate.get('WORKSHOP').toPromise();
    this.storei18n = await this.translate.get('STORE').toPromise();
  }

  getUrlItem(item): string[] {
    if (item.invoiceType === 'order') {
      return ['/pos/orders', item.objectId];
    } else if (item.invoiceType === 'bikeservicesheet') {
      return ['/servicesheets', item.objectId];
    }
  }

  getHtmlFieldContent(item, field): any {
    let htmlResponse = '';
    if (field === 'printHTML') {
      let pdfUrl = '';
      if (item.invoiceType === 'order') {
        pdfUrl = `${environment.apiUrl}/pos/order/reports/${item.objectId}/invoice?tk=${this.usersService.userTokenValue.token}&print=1`;
      } else if (item.invoiceType === 'bikeservicesheet') {
        pdfUrl = `${environment.apiUrl}/bikes/bss/reports/${item.objectId}/invoice?tk=${this.usersService.userTokenValue.token}&print=1`;
      }
      htmlResponse = '<a style="color: black; text-decoration: none; font-size: 20pt;" href="' + pdfUrl + '" target="_blank">🖨️</a>';
    }
    if (field === 'typeHTML') {
      if (item.invoiceType === 'order') {
        htmlResponse = `<span>${this.storei18n}</span>`;
      } else if (item.invoiceType === 'bikeservicesheet') {
        htmlResponse = `<span>${this.workshopi18n}</span>`;
      }
    }
    return this.sanitized.bypassSecurityTrustHtml(htmlResponse);
  }

  onRowClick(el: PosCategoryProduct, colDef?: string): void {
    if (colDef === 'printHTML') {
      // block row click
      return;
    }
    super.onRowClick(el, colDef);
  }
}
