import {TitleService} from 'src/app/services/title.service';
import {Component, OnInit} from '@angular/core';
import dayjs from 'dayjs';

@Component({
  selector: 'app-statistics-workshop',
  templateUrl: './statistics-workshop.component.html',
  styleUrls: ['./statistics-workshop.component.scss']
})
export class StatisticsWorkshopComponent implements OnInit {

  bssMeasurement = 'bss_count';
  incomeMeasurement = 'total_income_with_vat';
  incomeSeries = ['total_tasks', 'total_products'];

  interval = '1mo';
  startDt = '';
  endDt = '';

  constructor(
    private titleService: TitleService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitleTranslated('CTX_STATS.STATISTICS');
    this.setupChart();
  }

  setupChart(): void {
  //  https://day.js.org/docs/en/installation/installation
  //  This year
  //   this.startDt = dayjs().startOf('year').format('DD-MM-YYYY');
  //   this.endDt = dayjs().endOf('year').format('DD-MM-YYYY');
  //  Last 12 months:
    this.startDt = dayjs().subtract(12, 'month').startOf('month').format('DD-MM-YYYY');
    this.endDt = dayjs().endOf('month').format('DD-MM-YYYY');
  }

}
