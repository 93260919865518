import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, timer} from 'rxjs';
import {map, retry, switchMap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {UsersService} from './users.service';
import {BikeCRMApiAbstract} from './bikecrm-api-base';
import {BikeCRMApiStats} from '../models/api';


@Injectable({
  providedIn: 'root'
})
export class ServiceStatistics extends BikeCRMApiAbstract {
  apiPath = 'statistics';

  constructor(private userService: UsersService,
              protected http: HttpClient) {
    super(http);
  }

  getStatistics(measurement: string, interval: string, startDt: string, endDt: string): Observable<BikeCRMApiStats> {
    const obs = this.http.get<BikeCRMApiStats>(
      `${environment.apiUrl}/api/${this.apiPath}/`,
      {
        params: {
          measurement,
          interval,
          start_dt: startDt,
          end_dt: endDt,
        }
      }
    );
    // for now, refreshing the tasks from a sheet is causing duplicates, as you create one task,
    //          we save it, and then we retrieve the same one from the server
    return timer(0, environment.refreshRateUltraLow)
      .pipe(
        switchMap(() => obs),
        map((r) => r),
        retry(3)
      );
  }

}
