import {TitleService} from 'src/app/services/title.service';
import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {UsersService} from '../../services/users.service';

@Component({
  selector: 'app-reports-workshop',
  templateUrl: './reports-workshop.component.html',
  styleUrls: ['./reports-workshop.component.scss']
})
export class ReportsWorkshopComponent implements OnInit {
  years = [];

  currentYear: number = new Date().getFullYear();
  currentPeriod: number = Math.floor(new Date().getMonth() / 3) + 1;

  selectedYear = this.currentYear;
  selectedPeriodLiteral = 'Q' + this.currentPeriod.toString();  // Default to current quarter
  selectedPeriod = this.currentPeriod.toString();  // Default to current quarter
  selectedPeriodType = 'quarter';

  constructor(
    private titleService: TitleService,
    private usersService: UsersService,
  ) {
  }

  ngOnInit(): void {
    this.titleService.setTitleTranslated('CTX_REPORTS.REPORTS');

    const startYear = 2020;
    for (let i = startYear; i <= this.currentYear; i++) {
      this.years.push(i);
    }
  }
  onSelectedPeriodChange(ev): void {
    // console.log('onSelectedPeriodChange', ev);
    // console.log('onSelectedPeriodChange', ev.value);
    if (ev.value.includes('Q')) {
      this.selectedPeriodType = 'quarter';
      this.selectedPeriod = ev.value.replace('Q', '');
    } else {
      this.selectedPeriodType = 'month';
      this.selectedPeriod = ev.value.replace('M', '');
    }
  }

  downloadFinishedBssCSV(): void {
    window.open(`${environment.apiUrl}/bikes/bss/reports/export/csv?tk=${this.usersService.userTokenValue.token}&year=${this.selectedYear}&${this.selectedPeriodType}=${this.selectedPeriod}`, '_blank');
  }

  downloadSalesCSV(): void {
    window.open(`${environment.apiUrl}/pos/sales/reports/export/csv?tk=${this.usersService.userTokenValue.token}&year=${this.selectedYear}&${this.selectedPeriodType}=${this.selectedPeriod}`, '_blank');
  }

  downloadDetailedSalesPDF(): void {
    window.open(`${environment.apiUrl}/pos/sales/reports/detailed/export/pdf?tk=${this.usersService.userTokenValue.token}&year=${this.selectedYear}&${this.selectedPeriodType}=${this.selectedPeriod}`, '_blank');
  }

  downloadProductSalesCSV(): void {
    window.open(`${environment.apiUrl}/pos/sales/products/reports/export/csv?tk=${this.usersService.userTokenValue.token}&year=${this.selectedYear}&${this.selectedPeriodType}=${this.selectedPeriod}`, '_blank');
  }

  downloadClientsXls(): void {
    window.open(`${environment.apiUrl}/users/clients/reports/export/xls?tk=${this.usersService.userTokenValue.token}`, '_blank');
  }

  downloadProductListXls(): void {
    window.open(`${environment.apiUrl}/pos/products/reports/export/xls?tk=${this.usersService.userTokenValue.token}`, '_blank');
  }

  downloadroPaymentMethodXls(method: string): void {
    window.open(`${environment.apiUrl}/finances/payment_method/reports/export/xls?tk=${this.usersService.userTokenValue.token}&year=${this.selectedYear}&${this.selectedPeriodType}=${this.selectedPeriod}&payment_method=${method}`, '_blank');
  }

  downloadSupplierOrders(): void {
    window.open(`${environment.apiUrl}/pos/sales/suppliers_orders/reports/export/xls?tk=${this.usersService.userTokenValue.token}&year=${this.selectedYear}&${this.selectedPeriodType}=${this.selectedPeriod}`, '_blank');
  }

}
