import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {
  ChildItemEditableListAbstractComponent,
  ChildItemFieldMetadata,
  ChildItemFieldTypes,
} from '../abstract-child-editable-item-list/abstract-child-editable-item-list.component';
import {Validators} from '@angular/forms';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {MoneyService} from '../../services/money.service';
import {UsersService} from '../../services/users.service';
import {BikesListV2Component} from '../../pages/bikes-list-v2/bikes-list-v2.component';
import {Bike} from '../../models/bike';
import {RentedBikesService} from '../../services/rented-bikes.service';
import {RentedBike} from '../../models/rental';
import {CurrencyPipe} from '@angular/common';

@Component({
  selector: 'app-rental-rented-bikes-list',
  templateUrl: '../abstract-child-editable-item-list/abstract-child-editable-item-list.component.html',
  styleUrls: ['../abstract-child-editable-item-list/abstract-child-editable-item-list.component.scss'],
  // TODO: Document
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RentalRentedBikesListComponent extends ChildItemEditableListAbstractComponent<RentedBike> implements OnInit {
  parentApiRelName = 'rental';

  @Output() itemsUpdated = new EventEmitter();

  addItemLiteralI18n = 'ADD_BIKE';

  @Input() showFooter = true;
  @Input() showVat = false;
  // TODO: show only if there is some discount in the order
  parentApiContentTypeName = 'rental';

  rentedBikes$: Observable<RentedBike[]>;

  fields = {
    name: {
      // tslint:disable-next-line:max-line-length
      metadata: new ChildItemFieldMetadata('BIKE_NAME', 'ADD_BIKE_NAME', ChildItemFieldTypes.text, '---', true, [Validators.required], false),
      mainInput: true,
      autoCompleteOptions: [],
      w: 40,
      align: 'start'
    },
    discountPercent: {
      metadata: new ChildItemFieldMetadata('DSC_PERCENT', 'ADD_DISCOUNT', ChildItemFieldTypes.calculated, 0, true, null, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    vat: {
      metadata: new ChildItemFieldMetadata('VAT', 'ADD_VAT', ChildItemFieldTypes.calculated, 0.21, false, null, false, true),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    // price with vat per unit
    price_with_vat: {
      metadata: new ChildItemFieldMetadata('TOTAL', 'ADD_PRICE', ChildItemFieldTypes.calculated, 0, true, null, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    more_opts: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.action, null, true, null, false, false, true),
      mainInput: false,
      w: 10,
      align: 'end'
    },
    price: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.currency, 0, false, null, false, true),
      mainInput: false
    },
    autoCalculatePrice: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.boolean, true, false, null, false, true),
      mainInput: false
    },
    bike: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.foreign_key, null, false, null, false, true),
      mainInput: false
    },
  };

  @ViewChild(MatTable, {static: true}) table: MatTable<RentedBike>;
  dataSource = new MatTableDataSource();

  defaultVat: number;

  expandedFields: { [key: string]: Set<string> } = {};

  constructor(
    dialog: MatDialog,
    translate: TranslateService,
    notificationService: CrmNotificationsService,
    protected rentedBikesService: RentedBikesService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected moneyService: MoneyService,
    protected usersService: UsersService
  ) {
    super(dialog, translate, notificationService, rentedBikesService, nativeInterfacesService, usersService);
  }

  ngOnInit(): void {
    this.defaultVat = Number(this.usersService.business.defaultVat);

    // tslint:disable-next-line:max-line-length
    this.fields.vat.metadata = new ChildItemFieldMetadata('VAT', 'ADD_VAT', ChildItemFieldTypes.calculated, this.defaultVat, false, null, false, true);

    super.ngOnInit();

    // TODO: why is this not managed by the abstract class?
    this.rentedBikes$ = this.rentedBikesService.getRentedBikes(this.parent.id);
    this.rentedBikes$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this.items = x;
      // console.log('rentedBikes$', this.items);
      this.dataSource.data = this.items;
      this.itemsUpdated.emit();
    });

    this.showVatFields(this.showVat);
  }

  showVatFields(show = true): void {
    this.fields.vat.metadata.showAsColumn = show;
    this.fields.vat.metadata.hidden = !show;
  }

  createNewItemInstance(): RentedBike {
    // TODO: this should not be necessary for rentals
    const item = new RentedBike();
    item.id = this._getID(item);
    // item.vat = this.defaultVat;
    return item;
  }

  getTotalCostItem(rentedBike: RentedBike): number {
    return RentedBike.totalCost(rentedBike);
  }

  getTotalCost(): number {
    if (this.items != null) {
      return this.items.reduce((sum, current) => sum + (RentedBike.totalCost(current) || 0), 0);
    }
    return 0;
  }

  getInputTypeCalculated(field: string): string {
    if (field === 'price_with_vat') {
      return 'simple_html';
    }
    if (field === 'vat') {
      return 'percent';
    }
    if (field === 'discountPercent') {
      return 'percent';
    }
    throw Error(`getInputTypeCalculated field: ${field} not found`);
  }

  getCalculatedField(rentedBike: RentedBike, field: string): any {
    if (field === 'price_with_vat') {
      return this.moneyService.addVat(rentedBike.price, rentedBike.vat, 2);
    }
    if (field === 'vat') {
      return this.moneyService.basicPointsToPercent(rentedBike.vat);
    }
    if (field === 'discountPercent') {
      return rentedBike.discountPercent;
    }
    throw Error(`getCalculatedField field: ${field} not found`);
  }

  onEditStatusChange(s: boolean, rentedBike = null, colDef = null): void {
    super.onEditStatusChange(s, colDef);
  }

  getCalculatedFieldDisplay(rentedBike: RentedBike, field: string): any {
    if (field === 'price_with_vat') {
      // return this.moneyService.addVat(rentedBike.price, rentedBike.vat, 2);
      const price = this.moneyService.addVat(rentedBike.price, rentedBike.vat, 2);
      let priceLiteral = price.toFixed(2);
      priceLiteral = new CurrencyPipe(this.usersService.business.locale, this.currencyCode).transform(priceLiteral, this.currencyCode, 'symbol-narrow');
      if (rentedBike.priceRecommended && rentedBike.priceRecommended !== 0 && rentedBike.priceRecommended !== rentedBike.price) {
        const priceRecommended = this.moneyService.addVat(rentedBike.priceRecommended, rentedBike.vat, 2);
        let priceRecommendedLiteral = priceRecommended.toFixed(2);
        priceRecommendedLiteral = new CurrencyPipe(this.usersService.business.locale, this.currencyCode).transform(priceRecommendedLiteral, this.currencyCode, 'symbol-narrow');
        return `<div style="display: flex; flex-direction: column"><small style="text-decoration: line-through">${priceRecommendedLiteral}</small><span style="margin-left: 2px">${priceLiteral}</span></div>`;
      }
      return `<span class="price">${priceLiteral}</span>`;
    }
    return this.getCalculatedField(rentedBike, field);
  }

  setCalculatedField(rentedBike: RentedBike, modifiedText: string, field: string): void {
    modifiedText = this.cleanInputToString(modifiedText, true);
    const inputAsNumber = Number(modifiedText);
    if (field === 'price_with_vat') {
      rentedBike.price = this.moneyService.removeVat(inputAsNumber, rentedBike.vat);
      rentedBike.priceRecommended = rentedBike.price;
      rentedBike.autoCalculatePrice = false;
      this.saveItem(rentedBike);
      return;
    }
    if (field === 'vat') {
      rentedBike.vat = this.moneyService.percentToBasicPoints(inputAsNumber);
      this.saveItem(rentedBike);
      return;
    }
    if (field === 'discountPercent') {
      if (!rentedBike.priceRecommended || rentedBike.priceRecommended === 0) {
        rentedBike.priceRecommended = rentedBike.price;
      }
      let finalPrice = rentedBike.priceRecommended - (rentedBike.priceRecommended * this.moneyService.percentToBasicPoints(inputAsNumber));
      finalPrice = +finalPrice.toFixed(10);
      rentedBike.price = finalPrice;
      this.saveItem(rentedBike);
      return;
    }
    throw Error(`setCalculatedField field: ${field} not found`);
  }

  addRentedBikeDialog(): void {
    const dialogRef = this.dialog.open(BikesListV2Component, {
      height: '90%',
      width: '90%',
      maxWidth: '90%',
      panelClass: 'no-padding-dialog-container',
      data: {
        mode: 'rental-bike-selector',
        rentalbikesAvailabilityId: this.parent.id,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      const b: Bike = result;
      // TODO: add bike to Rental
      this.addRentedBikeFromBike(b);
    });
  }

  addRentedBikeFromBike(b: Bike): void {
    if (b == null) {
      return;
    }
    const newRentedBike = RentedBike.createFromBike(b);
    this.addItemEnd(newRentedBike);
  }

  isKeyboardBarCodeScannerEnabled(): boolean {
    return !this.isEditing;
  }

}
