import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NativeInterfacesService} from '../../../services/native-interfaces.service';
import {environment} from '../../../../environments/environment';


class ErrorDialogComponent {
}

@Component({
  selector: 'app-print-label-dialog-component',
  template: `
<!--    <div class="p2"><h2>{{ "PRINT_LABELS" | translate }}</h2>-->
<!--    TODO: change to mm options instead of columns   -->
<!--    <div>-->
<!--      <label>{{ 'NUMBER_OF_COLUMNS' | translate }}</label>-->
<!--      <br>-->
<!--      <mat-button-toggle-group [(ngModel)]="numColumns">-->
<!--        <mat-button-toggle [value]="1">1</mat-button-toggle>-->
<!--        <mat-button-toggle [value]="2">2</mat-button-toggle>-->
<!--        <mat-button-toggle [value]="3">3</mat-button-toggle>-->
<!--      </mat-button-toggle-group>-->
<!--    </div>-->
<!--    <form (ngSubmit)="onSave(numLabels.value)" [formGroup]="simpleForm">-->
<!--    <mat-form-field>-->
<!--      <label>{{ 'NUMBER_OF_LABELS' | translate }}</label>-->
<!--      <input type="number" value="1" matInput autocomplete="off" #numLabels>-->
<!--    </mat-form-field>-->
<!--    <br>-->
<!--    <br>-->
<!---->
<!--    <button type="submit" [disabled]="!simpleForm.valid" mat-button>{{ "PRINT" | translate }}</button>-->
<!--  </form></div>-->
<!-- -->
  `
})
export class PrintLabelsDialogComponent {
  // TODO: a little of overlap with simple text dialog, maybe we can have a generic
  //  dialog that you can pass different inputs

  simpleForm: UntypedFormGroup;

  constructor(public dialog: MatDialogRef<ErrorDialogComponent>,
              fb: UntypedFormBuilder,
              public native: NativeInterfacesService,
              @Inject(MAT_DIALOG_DATA) public data: {product_id: string}) {
    this.simpleForm = fb.group({});

    window.open(`${environment.apiUrl}/pos/products/print/labels?product_id=${this.data.product_id}&number_of_labels=1&number_of_columns=1&print=1`, '_blank');
    this.dialog.close();
  }

}
