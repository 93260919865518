import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterList' })
export class FilterListPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    }
    return value.filter(
      (item: any) => {
        let found = false;
        if (item.hasOwnProperty('name') && !found) {
          found = item.name.toLowerCase().indexOf(args.toLowerCase()) > -1;
        }
        if (item.hasOwnProperty('phoneNumber') && !found) {
          found = item.phoneNumber.toLowerCase().indexOf(args.toLowerCase()) > -1;
        }
        if (item.hasOwnProperty('email') && !found) {
          found = item.email.toLowerCase().indexOf(args.toLowerCase()) > -1;
        }
        if (item.hasOwnProperty('ownerName') && !found) {
          found = item.ownerName && item.ownerName.toLowerCase().indexOf(args.toLowerCase()) > -1;
        }
        if (item.hasOwnProperty('brand') && !found) {
          found = item.brand && item.brand.toLowerCase().indexOf(args.toLowerCase()) > -1;
        }
        if (item.hasOwnProperty('model') && !found) {
          found = item.model && item.model.toLowerCase().indexOf(args.toLowerCase()) > -1;
        }
        if (item.hasOwnProperty('type') && !found) {
          found = item.type && item.type.toLowerCase().indexOf(args.toLowerCase()) > -1;
        }
        return found;
      }
    );
  }

}
