import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {
  ChildItemEditableListAbstractComponent,
  ChildItemFieldMetadata,
  ChildItemFieldTypes,
} from '../abstract-child-editable-item-list/abstract-child-editable-item-list.component';
import {Validators} from '@angular/forms';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {MoneyService} from '../../services/money.service';
import {UsersService} from '../../services/users.service';
import {RentalAccessory, RentedAccessory} from '../../models/rental';
import {RentedAccesoriesService} from '../../services/rented-accesories.service';
import {RentalsAccessoriesListComponent} from '../../pages/rentals-accessories-list/rentals-accessories-list.component';
import {CurrencyPipe} from '@angular/common';

@Component({
  selector: 'app-rental-rented-accessories-list',
  templateUrl: '../abstract-child-editable-item-list/abstract-child-editable-item-list.component.html',
  styleUrls: ['../abstract-child-editable-item-list/abstract-child-editable-item-list.component.scss'],
  // TODO: Document
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RentalRentedAccessoriesListComponent extends ChildItemEditableListAbstractComponent<RentedAccessory> implements OnInit {
  parentApiRelName = 'rental';

  @Output() itemsUpdated = new EventEmitter();

  addItemLiteralI18n = 'ADD_ACCESSORY';

  @Input() showFooter = true;
  @Input() showVat = false;
  // TODO: show only if there is some discount in the order
  parentApiContentTypeName = 'rental';

  rentedAccessories$: Observable<RentedAccessory[]>;


  fields = {
    name: {
      // tslint:disable-next-line:max-line-length
      metadata: new ChildItemFieldMetadata('ACCESSORY_NAME', 'ADD_ACCESSORY_NAME', ChildItemFieldTypes.text, '---', true, [Validators.required], false),
      mainInput: true,
      autoCompleteOptions: [],
      w: 40,
      align: 'start'
    },
    discountPercent: {
      metadata: new ChildItemFieldMetadata('DSC_PERCENT', 'ADD_DISCOUNT', ChildItemFieldTypes.calculated, 0, true, null, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    vat: {
      metadata: new ChildItemFieldMetadata('VAT', 'ADD_VAT', ChildItemFieldTypes.calculated, 0.21, false, null, false, true),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    price_with_vat: {
      metadata: new ChildItemFieldMetadata('TOTAL', 'ADD_PRICE', ChildItemFieldTypes.calculated, 0, true, null, false),
      mainInput: false,
      w: 15,
      align: 'end'
    },
    more_opts: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.action, null, true, null, false),
      mainInput: false,
      w: 10,
      align: 'end'
    },
    price: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.currency, 0, false, null, false, true),
      mainInput: false
    },
    bike: {
      metadata: new ChildItemFieldMetadata('', '', ChildItemFieldTypes.foreign_key, null, false, null, false, true),
      mainInput: false
    },
  };

  @ViewChild(MatTable, {static: true}) table: MatTable<RentedAccessory>;
  dataSource = new MatTableDataSource();

  defaultVat: number;

  expandedFields: { [key: string]: Set<string> } = {};

  constructor(
    dialog: MatDialog,
    translate: TranslateService,
    notificationService: CrmNotificationsService,
    protected rentedAccessorysService: RentedAccesoriesService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected moneyService: MoneyService,
    protected usersService: UsersService
  ) {
    super(dialog, translate, notificationService, rentedAccessorysService, nativeInterfacesService, usersService);
  }

  ngOnInit(): void {
    this.defaultVat = Number(this.usersService.business.defaultVat);

    // tslint:disable-next-line:max-line-length
    this.fields.vat.metadata = new ChildItemFieldMetadata('VAT', 'ADD_VAT', ChildItemFieldTypes.calculated, this.defaultVat, false, null, false, true);

    super.ngOnInit();

    // TODO: why is this not managed by the abstract class?
    this.rentedAccessories$ = this.rentedAccessorysService.getRentedAccessories(this.parent.id);
    this.rentedAccessories$.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this.items = x;
      this.dataSource.data = this.items;
      this.itemsUpdated.emit();
    });

    this.showVatFields(this.showVat);
  }

  showVatFields(show = true): void {
    this.fields.vat.metadata.showAsColumn = show;
    this.fields.vat.metadata.hidden = !show;
  }

  createNewItemInstance(): RentedAccessory {
    // TODO: this should not be necessary for rentals
    const item = new RentedAccessory();
    item.id = this._getID(item);
    // item.vat = this.defaultVat;
    return item;
  }

  getTotalCostItem(rentedAccessory: RentedAccessory): number {
    return RentedAccessory.totalCost(rentedAccessory);
  }

  getTotalCost(): number {
    if (this.items != null) {
      return this.items.reduce((sum, current) => sum + (RentedAccessory.totalCost(current) || 0), 0);
    }
    return 0;
  }

  getInputTypeCalculated(field: string): string {
    if (field === 'price_with_vat') {
      return 'simple_html';
    }
    if (field === 'vat') {
      return 'percent';
    }
    if (field === 'discountPercent') {
      return 'percent';
    }
    throw Error(`getInputTypeCalculated field: ${field} not found`);
  }

  getCalculatedField(rentedAccessory: RentedAccessory, field: string): any {
    if (field === 'price_with_vat') {
      return this.moneyService.addVat(rentedAccessory.price, rentedAccessory.vat, 2);
    }
    if (field === 'vat') {
       return this.moneyService.basicPointsToPercent(rentedAccessory.vat);
    }
    if (field === 'discountPercent') {
      return rentedAccessory.discountPercent;
    }
    throw Error(`getCalculatedField field: ${field} not found`);
  }

  onEditStatusChange(s: boolean, rentedAccessory = null, colDef = null): void {
    super.onEditStatusChange(s, colDef);
  }

  getCalculatedFieldDisplay(rentedAccessory: RentedAccessory, field: string): any {
    if (field === 'price_with_vat') {
      // return this.moneyService.addVat(rentedAccessory.price, rentedAccessory.vat, 2);
      const price = this.moneyService.addVat(rentedAccessory.price, rentedAccessory.vat, 2);
      let priceLiteral = price.toFixed(2);
      priceLiteral = new CurrencyPipe(this.usersService.business.locale, this.currencyCode).transform(priceLiteral, this.currencyCode, 'symbol-narrow');
      if (rentedAccessory.priceRecommended && rentedAccessory.priceRecommended !== 0 && rentedAccessory.priceRecommended !== rentedAccessory.price) {
        const priceRecommended = this.moneyService.addVat(rentedAccessory.priceRecommended, rentedAccessory.vat, 2);
        let priceRecommendedLiteral = priceRecommended.toFixed(2);
        priceRecommendedLiteral = new CurrencyPipe(this.usersService.business.locale, this.currencyCode).transform(priceRecommendedLiteral, this.currencyCode, 'symbol-narrow');
        return `<div style="display: flex; flex-direction: column"><small style="text-decoration: line-through">${priceRecommendedLiteral}</small><span style="margin-left: 2px">${priceLiteral}</span></div>`;
      }
      return `<span class="price">${priceLiteral}</span>`;
    }
    return this.getCalculatedField(rentedAccessory, field);
  }

  setCalculatedField(rentedAccessory: RentedAccessory, modifiedText: string, field: string): void {
    modifiedText = this.cleanInputToString(modifiedText, true);
    const inputAsNumber = Number(modifiedText);
    if (field === 'price_with_vat') {
      rentedAccessory.price = this.moneyService.removeVat(inputAsNumber, rentedAccessory.vat);
      rentedAccessory.priceRecommended = rentedAccessory.price;
      rentedAccessory.autoCalculatePrice = false;
      this.saveItem(rentedAccessory);
      return;
    }
    if (field === 'vat') {
      rentedAccessory.vat = this.moneyService.percentToBasicPoints(inputAsNumber);
      this.saveItem(rentedAccessory);
      return;
    }
    if (field === 'discountPercent') {
      if (!rentedAccessory.priceRecommended || rentedAccessory.priceRecommended === 0) {
        rentedAccessory.priceRecommended = rentedAccessory.price;
      }
      let finalPrice = rentedAccessory.priceRecommended - (rentedAccessory.priceRecommended * this.moneyService.percentToBasicPoints(inputAsNumber));
      finalPrice = +finalPrice.toFixed(10);
      rentedAccessory.price = finalPrice;
      this.saveItem(rentedAccessory);
      return;
    }
    throw Error(`setCalculatedField field: ${field} not found`);
  }

  addRentedAccessoryDialog(): void {
    // TODO: this should open list of accessories not bikes
    const dialogRef = this.dialog.open(RentalsAccessoriesListComponent, {
      height: '90%',
      width: '90%',
      maxWidth: '90%',
      panelClass: 'no-padding-dialog-container',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      const a: RentalAccessory = result;
      this.addRentedAccessoryFromAccesory(a);
    });
  }

  addRentedAccessoryFromAccesory(a: RentalAccessory): void {
    if (a == null) {
      return;
    }
    const newRentedAccessory = RentedAccessory.createFromAccessory(a);
    this.addItemEnd(newRentedAccessory);
  }

  isKeyboardBarCodeScannerEnabled(): boolean {
    return !this.isEditing;
  }

}
