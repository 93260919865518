<div class="base-paddings">
    <ng-container *ngIf="parentId">
        <ng-container *ngIf="parentItem; else loading">

            <!-- Fab -->
            <app-custom-fab [primaryFab]="primaryFab" [secondaryFab]="secondaryFab" (fabAction)="onFabAction($event)"></app-custom-fab>

            <!--                  TODO: extract this to some component -->
            <div *ngIf="showSharePrintButtons" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="14px">
              <img [matMenuTriggerFor]="sendMenuWhatsapp" alt="Send whatsapp" class="mr2 pointer" style="width: 24px;" src="/assets/images/icons/whatsapp_fill.svg"/>
<!--              <mat-icon [matMenuTriggerFor]="sendMenuMail" class="pointer">mail</mat-icon>-->
<!--              <mat-icon [matMenuTriggerFor]="printMenu" class="pointer">print</mat-icon>-->
<!--              <mat-icon [matMenuTriggerFor]="downloadPDFMenu" class="pointer">picture_as_pdf</mat-icon>-->
<!--              <mat-icon [matMenuTriggerFor]="moreBssOptsMenu" class="pointer">more_vert</mat-icon>-->
            </div>

            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
                <!-- Left/top info pane -->
                <div fxFlex.gt-sm="30" class="contrast-bg h100" fxLayout="column">
                  <div fxLayout="column" fxLayoutAlign="center center" class="pt2">
                    <h1 *ngIf="titleI18N">{{ titleI18N | translate }}</h1>
                    <mat-icon *ngIf="titleIcon" aria-hidden="true" aria-label="" style="font-size: 140px; color: gray; width: 140px; height: 140px;">{{ titleIcon }}</mat-icon>
                  </div>
                    <!-- Order info -->
                    <div class="p2" fxLayout="column">
                      <!-- TODO: better abstract this, but for now, every class that inherits this can add here some specific code -->
                        <ng-container *ngIf="!parentItem.hasOwnProperty('balance')">
                          <span *ngIf="parentItem.shortPrettyId">{{ 'ID' | translate }}: {{ parentItem.shortPrettyId }}</span>
                          <span>{{ 'DATE' | translate }}: {{ parentItem.createdAt | date }}</span>
                          <span *ngIf="parentItem.pendingItemsToScannCount">{{ 'PENDING_ITEMS_TO_SCAN' | translate }}: {{ parentItem.pendingItemsToScannCount }}</span>
                        </ng-container>

                        <!-- TODO this is crying for a refactor:  -->
                        <!-- Supplier Order:  -->
                        <ng-container *ngIf="parentItem.hasOwnProperty('documentType') && parentItem.hasOwnProperty('supplierName')">
                          <mat-form-field>
                            <mat-label>{{ "DOCUMENT_TYPE" | translate }}</mat-label>
                            <mat-select (selectionChange)="selectedDocumentType($event.value)" [value]="parentItem.documentType">
                              <mat-option value="invoic">{{ "INVOICE" | translate }}</mat-option>
                              <mat-option value="ticket">{{ "TICKET" | translate }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>{{ "DOCUMENT_NUMBER" | translate }}</mat-label>
                            <input matInput [(ngModel)]="parentItem.documentNumber" (change)="changedDocumentNumber()">
                          </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="parentItem.hasOwnProperty('supplierName')">
                          <span>{{ 'SUPPLIER' | translate }}: {{ parentItem.supplierName }}</span>
                        </ng-container>

                        <!-- TODO this is crying for a refactor:  -->
                        <!-- Rental: if fields start_datetime and pickup_datetime -->
                        <ng-container *ngIf="parentItem.hasOwnProperty('startDatetime') && parentItem.hasOwnProperty('pickupDatetime')">
                          <img *ngIf="parentItem.freehandSignatureUrl" [src]="parentItem.freehandSignatureUrl" alt="Freehand signature" style="max-width: 100%; max-height: 100px; object-fit: contain;">
                          <mat-form-field>
                            <mat-label>{{ "RENTAL_STATUS" | translate }}</mat-label>
                            <mat-select (selectionChange)="changeRentalStatus($event.value)" [value]="parentItem.status">
                              <mat-option value="draft">{{ "DRAFT" | translate }}</mat-option>
                              <mat-option value="reserved">{{ "RESERVED" | translate }}</mat-option>
                              <mat-option value="rented">{{ "RENTED" | translate }}</mat-option>
                              <mat-option value="returned">{{ "RETURNED" | translate }}</mat-option>
                              <mat-option value="cancelled">{{ "CANCELLED" | translate }}</mat-option>
                            </mat-select>
                          </mat-form-field>

                          <app-inline-edit type="datetime" [titleIsTheData]="false" [data]="parentItem.startDatetime" (focusOut)="startDatetimeChanged($event)" title="{{ 'RENTAL_START_DATE' | translate }}"></app-inline-edit>
                          <app-inline-edit type="datetime" [titleIsTheData]="false" [data]="parentItem.endDatetime" (focusOut)="endDatetimeChanged($event)" title="{{ 'RENTAL_END_DATE' | translate }}"></app-inline-edit>
                        </ng-container>
                        <!-- TODO this is crying for a refactor:  -->
                        <!-- Rental tariff -->
                        <ng-container *ngIf="parentItem.hasOwnProperty('excessDurationEveryHours') && parentItem.hasOwnProperty('excessDurationPrice')">
                          <mat-form-field>
                            <mat-label>{{ "TARIFF_NAME" | translate }}</mat-label>
                            <input matInput [(ngModel)]="parentItem.name" (change)="changedTariffName()">
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>{{ "TARIFF_EXCESS_HOURS_FREQUENCY" | translate }}</mat-label>
                            <input matInput [(ngModel)]="parentItem.excessDurationEveryHours" (change)="excessDurationEveryHoursChanged()">
                          </mat-form-field>
                          <mat-form-field>
                            <mat-label>{{ "TARIFF_EXCESS_PRICE" | translate }}</mat-label>
                            <input matInput [value]="excessDurationPriceWithVat" (change)="excessDurationPriceChanged($event)">
                          </mat-form-field>
                        </ng-container>

                        <!-- TODO: better abstract this, but for now, every class that inherits this can add here some specific code -->
                        <ng-container *ngIf="parentItem.hasOwnProperty('clientName')">
                          <!-- TODO: use component client detail in small mode (maybe set a template here of customInfo) and there add the component -->
                          <span>{{ 'CLIENT' | translate }}: {{ parentItem.clientName }}</span>
                        </ng-container>

<!--                        <mat-divider></mat-divider>-->
                        <ng-container *ngIf="parentItem.hasOwnProperty('invoiceSeries')">
                          <app-invoice-controls
                            [invoiceSeries]="parentItem.invoiceSeries"
                            [invoiceNumber]="parentItem.invoiceNumber"
                            [invoiceDate]="parentItem.invoiceDate"
                            (invoiceSeriesChange)="changeInvoiceSeries($event)"
                            (generateInvoiceEvent)="generateInvoice()">
                          </app-invoice-controls>
                        </ng-container>
<!--                        <mat-divider></mat-divider>-->

                    </div>
                    <mat-divider class="pb2" *ngIf="actions.length > 0"></mat-divider>

                  <!-- Actions -->
                    <div class="p2" fxLayout="column" *ngIf="actions.length > 0">
                      <ng-container *ngFor="let a of (actions.length == 4 ? actions : actions.slice(0,3)); first as isFirst">
                        <button mat-stroked-button [disabled]="!a.enabled"
                                class="mt2"
                                color="{{ isFirst ? 'primary' : 'accent' }}" (click)="onAction(a.id)">{{ a.i18nKey | translate}}</button>
                      </ng-container>
                      <ng-container *ngIf="actions.length > 4">
                        <button class="mt2" mat-button color="accent" [matMenuTriggerFor]="menu_more_actions">
                          <!-- <mat-icon>arrow_drop_down</mat-icon> -->
                          {{ 'MORE_ACTIONS' | translate | uppercase }}
                        </button>
                      </ng-container>
                    </div> <!-- Actions End -->
                </div>

                <!-- Right/bottom info pane -->
                <div fxFlex.gt-sm="70" class="pb4 mb4 h100">
                  <template #childListHost></template>
                  <ng-container *ngIf="isParentBillableItem()">
                    <app-payment-status-card #paymentCard [billableItem]="parentItem"></app-payment-status-card>
                  </ng-container>

                </div>
            </div>

        </ng-container>
        <ng-template #loading>loading...</ng-template>
    </ng-container>

    <mat-menu #menu_more_actions="matMenu">
      <ng-container *ngFor="let a of actions.slice(3,20); first as isFirst">
          <button mat-menu-item (click)="onAction(a.id)">{{ a.i18nKey | translate}}</button>
      </ng-container>
    </mat-menu>

    <mat-menu #sendMenuWhatsapp="matMenu">
      <!--  TODO: can we merge this menu with the sendMenu to avoid so many duplicate code? -->
<!--      <button mat-menu-item-->
<!--              (click)="sendReceiptWhatsApp()"-->
<!--              [disabled]="serviceSheet && serviceSheet.ownerPhone?.length < 3">-->
<!--        {{ 'RECEIPT' | translate }}-->
<!--        <ng-container *ngIf="serviceSheet && serviceSheet.ownerPhone?.length < 3"><br><small>{{ "USER_DOES_NOT_HAVE_ANY_PHONE" | translate }}</small></ng-container>-->
<!--      </button>-->

      <button mat-menu-item
              (click)="sendDocumentWhatsapp('ticket')">
<!--              [disabled]="serviceSheet && (serviceSheet.ownerPhone?.length < 3 || !serviceSheet.closed)"-->
        {{ 'TICKET' | translate }}
<!--        <ng-container *ngIf="serviceSheet && serviceSheet.ownerPhone?.length < 3"><br><small>{{ "USER_DOES_NOT_HAVE_ANY_PHONE" | translate }}</small></ng-container>-->
<!--        <ng-container *ngIf="serviceSheet && (serviceSheet.ownerPhone?.length < 3 || !serviceSheet.closed)"><br><small>{{ "SERVICE_SHEET_NOT_CLOSED" | translate }}</small></ng-container>-->
      </button>

      <!--  <button mat-menu-item (click)="sendTicketClient()">{{ 'TICKET' | translate }}</button>-->

<!--      <button mat-menu-item-->
<!--              (click)="sendBudgetClientWhatsApp(); trackNewSendPrintButtonsUsed()"-->
<!--              [disabled]="(serviceSheet && serviceSheet.closed) || (serviceSheet && serviceSheet.ownerPhone?.length < 3)">-->
<!--        {{ 'BUDGET' | translate }}-->
<!--        <ng-container *ngIf="serviceSheet && serviceSheet.closed"><br><small>{{ "SERVICE_SHEET_CLOSED" | translate }}</small></ng-container>-->
<!--        <ng-container *ngIf="serviceSheet && serviceSheet.ownerPhone?.length < 3"><br><small>{{ "USER_DOES_NOT_HAVE_ANY_PHONE" | translate }}</small></ng-container>-->
<!--      </button>-->
<!--      <button mat-menu-item-->
<!--              (click)="sendInvoiceWhatsApp(); trackNewSendPrintButtonsUsed()"-->
<!--              [disabled]="(serviceSheet && !serviceSheet.closed) || (serviceSheet && serviceSheet.ownerPhone.length < 3)">-->
<!--        {{ 'INVOICE' | translate }}-->
<!--        <ng-container *ngIf="serviceSheet && !serviceSheet.closed"><br><small>{{ "SERVICE_SHEET_NOT_CLOSED" | translate }}</small></ng-container>-->
<!--        <ng-container *ngIf="serviceSheet && serviceSheet.ownerPhone?.length < 3"><br><small>{{ "USER_DOES_NOT_HAVE_ANY_PHONE" | translate }}</small></ng-container>-->
<!--      </button>-->
    </mat-menu>
</div>
