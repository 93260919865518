<!-- For detail -->
<ng-container *ngIf="bike && cardstyle !== 'gridable' && cardstyle !== 'button'">
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-sm="column"
          fxLayoutGap="15px"
          class="px2">
              <div fxFlex [routerLink]="detailed ? [] : ['/bikes/', bike.id]">
                  <app-image-kit-image
                      *ngIf="!getBikePictureUrl(bike).toLowerCase().includes('.svg')"
                      [imgSrc]="getBikePictureUrl(bike)"
                      [w]="getImageSizeWidth()"
                      [h]="getImageSizeHeight()">
                  </app-image-kit-image>

                  <img
                      *ngIf="getBikePictureUrl(bike).toLowerCase().includes('.svg')"
                      [style.width.px]="getImageSizeWidth()"
                      [style.height.px]="getImageSizeHeight()"
                      [ngStyle]="{'background': getCSSBackgroundColor()}"
                      style="
                          mask-image: url('{{ getBikePictureUrl(bike) }}');
                          -webkit-mask-image: url('{{ getBikePictureUrl(bike) }}');"
                      class="svgImg"/>
              </div>
              <div [fxFlex]="detailed? 40: 60" fxLayout="column" [fxLayoutAlign]="detailed? 'start': 'center'">
                  <h2 [routerLink]="detailed ? [] : ['/bikes/', bike.id]">{{ bike.name }}</h2>
  <!--                 <small>Id: {{bike.shortPrettyId}} </small>-->

                  <ng-container *ngIf="cardstyle !== 'minimal'">
                      <span *ngIf="bike.ownerName" [routerLink]="['/clients/', bike.owner]" class="link-action" fxLayoutAlign="start center" style="text-transform: capitalize;">
                          <mat-icon class="mr1">face</mat-icon> {{ bike.ownerName }}
                      </span>
  <!--                  TODO: translate i18n-->
                      <span *ngIf="!bike.ownerName">Owner: unassigned</span>
                      <span>Registered on: {{ prettyDate(bike.createdAt) }}</span>
                      <span *ngIf="bike.sn">{{ 'BIKE_SN' | translate }}: {{ bike.sn }}</span>
                  </ng-container>
              </div>
      </div>
      <app-inline-edit [data]="bike.roOdometer?.toString()"
                       title="{{ 'ODOMETER' | translate }}"
                       type="integer"
                       [suffix]="bike.roOdometer ? 'km' : ''"
                       (focusOut)="onOdometerChange($event)"
                       placeholder="{{ 'ADD_ODOMETER_READING' | translate }}"
                       addHint="{{ 'ADD_ODOMETER_READING' | translate }}"
                       class="m2"
      ></app-inline-edit>
    </div>
</ng-container>


<!-- TODO: unify code with the other card styles? -->
<!-- For list -->
<ng-container *ngIf="bike && (cardstyle === 'gridable' || cardstyle === 'button')">
    <mat-card appearance="outlined" [ngClass]="{'card-button': cardstyle === 'button'}">
        <mat-card-header>
            <mat-card-title (click)="onBikeClick()" class="link-action">{{ bike.name }}</mat-card-title>
            <mat-card-subtitle *ngIf="bike.ownerName && cardstyle !== 'button'" [routerLink]="['/clients/', bike.owner]" class="link-action">{{ bike.ownerName }}</mat-card-subtitle>
        </mat-card-header>

        <app-image-kit-image
                *ngIf="!getBikePictureUrl(bike).toLowerCase().includes('.svg')"
                (click)="onBikeClick()"
                class="link-action"
                style="padding-right: 1rem; padding-left: 1rem;"
                [imgSrc]="getBikePictureUrl(bike)"
                [w]="getImageSizeWidth()"
                [h]="getImageSizeHeight()">
            </app-image-kit-image>

            <img
                *ngIf="getBikePictureUrl(bike).toLowerCase().includes('.svg')"
                (click)="onBikeClick()"
                [style.width.px]="getImageSizeWidth()"
                [style.height.px]="getImageSizeHeight()"
                [ngStyle]="{'background': getCSSBackgroundColor()}"
                style="
                    padding-right: 1rem; padding-left: 1rem;
                    mask-image: url('{{ getBikePictureUrl(bike) }}');
                    -webkit-mask-image: url('{{ getBikePictureUrl(bike) }}');"
                class="svgImg link-action"/>

        <mat-card-content>
        </mat-card-content>
        <mat-card-actions *ngIf="cardstyle !== 'button'">
            <button mat-button [routerLink]="['/servicesheets/create', {bikeId: bike.id}]">{{ 'NEW_SERVICESHEET' | translate }}</button>
            <!-- <button mat-button>{{ 'EDIT' | translate }}</button> -->
            <!-- <button mat-button>{{ 'ARCHIVE' | translate }}</button> -->
        </mat-card-actions>
    </mat-card>
</ng-container>
