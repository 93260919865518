import packageInfo from '../../package.json';

export const environment = {
  production: true,
  isBeta: false,
  brand: 'skooterp',
  brandName: 'SkootERP',
  version: packageInfo.version + '-skooterp',
  apiUrl: 'https://api.bikecrm.app',
  publicApiUrl: 'https://connect.skooterp.com',
  refreshRateUltraLow: 1 * 3600 * 1000,
  refreshRateLow: 5 * 60 * 1000,
  refreshRateNormal: 120 * 1000,
  refreshRateHigh: 10 * 1000,
  imageKitPubKey: 'public_yCULDEoEmivv8E8xpPj7xiAOdBE=',
  imageKitUrlEndpoint: 'https://ik.imagekit.io/kigresoft/s3media/',
  firebase: {
    apiKey: 'AIzaSyC14doMjpMgXCTXNH7NadtLKC6UrR6zBEM',
    projectId: 'skooterp-crm',
    appId: '1:835806044408:web:3de865b27bcc7890ac9b0d',
    measurementId: 'G-SCTVERZ2EM'
  }

};
