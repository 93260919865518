import {Component, Inject, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Bike} from 'src/app/models/bike';
import {BikesService} from 'src/app/services/bikes.service';
import {CrmNotificationsService} from 'src/app/services/crm-notifications.service';
import {UsersService} from '../../services/users.service';
import {
  DetailField,
  DetailFieldTypes,
  ItemDetailAbstractComponent
} from '../../components/abstract-item-detail/abstract-item-detail.component';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {takeUntil} from 'rxjs/operators';
import {
  TabsUserRelatedComponentsComponent
} from '../../components/tabs-user-related-components/tabs-user-related-components.component';
import {ServicesheetListComponent} from '../servicesheet-list/servicesheet-list.component';
import {BssTaskListComponent} from '../../components/bss-task-list/bss-task-list.component';
import {
  BikesInstalledProductsListComponent
} from '../bikes-installed-products-list/bikes-installed-products-list.component';
import {BikeCardComponent} from '../../components/bike-card/bike-card.component';
import {environment} from '../../../environments/environment';
import {RentalsTariffsService} from '../../services/rentals-tariffs.service';
import {RentalTariff} from '../../models/rental_tariff';

// tslint:disable-next-line:variable-name
export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};


@Component({
  selector: 'app-bikes-detail',
  templateUrl: '../../components/abstract-item-detail/abstract-item-detail.component.html',
  styleUrls: ['../../components/abstract-item-detail/abstract-item-detail.component.scss']
})
export class BikesDetailComponent extends ItemDetailAbstractComponent<Bike> implements OnInit {
  navBase = '/bikes/';

  showTopComponent = true;
  hideDetailFields = true;

  printLabelsEnabled = true;

  bikeTypesSimpleList = null;

  editableFields = {
    name: new DetailField('NAME', DetailFieldTypes.text, '', ['start', 0, 100], [Validators.required]),
    type: new DetailField('BIKE_TYPE', DetailFieldTypes.selector, '', ['start', 1, 33]),
    frameSize: new DetailField('FRAME_SIZE', DetailFieldTypes.text, '', ['start', 1, 33]),
    sn: new DetailField('SERIAL_NUMBER', DetailFieldTypes.text, '', ['start', 1, 33]),
    rentalTariff: new DetailField('RENTAL_TARIFF', DetailFieldTypes.selector, '', ['start', 3, 33]),
    picture: new DetailField('MAIN_PICTURE', DetailFieldTypes.picture, '', ['start', 4, 33]),
    owner: new DetailField('OWNER', DetailFieldTypes.hidden, '', ['start', 4, 33]),
  };

  constructor(
    @Optional() public dialogRef: MatDialogRef<BikesDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { itemId: string, extraMessageI18nKey: string, mode: string },
    protected notificationService: CrmNotificationsService,
    protected activatedRoute: ActivatedRoute,
    protected translate: TranslateService,
    protected router: Router,
    protected bikesService: BikesService,
    protected formBuilder: UntypedFormBuilder,
    protected usersService: UsersService,
    protected dialog: MatDialog,
    protected nativeInterfacesService: NativeInterfacesService,
    protected rentalsTariffsService: RentalsTariffsService,
  ) {
    super(dialogRef,
      data,
      router,
      activatedRoute,
      notificationService,
      translate,
      formBuilder,
      dialog,
      bikesService,
      nativeInterfacesService,
      usersService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.router.url.includes('bikes_rental')) {
      this.initialCreateFormData.set('useCase', 'ren');
    } else {
      delete this.editableFields.rentalTariff;
    }
  }

  onFormReady(): void {
    super.onFormReady();
    if (this.router.url.includes('bikes_rental')) {
      this.itemForm.get('owner').setValue(this.usersService.userMe.id);
    }
    if (!this.itemForm.get('owner').value) {
      this.itemForm.get('owner').setValue(this.activatedRoute.snapshot.paramMap.get('ownerId'));
    }
  }

  loadDataItem(item: Bike = null): void {
    super.loadDataItem(item);
    // update bike card:
    this.loadTopComponent();
  }

  loadTopComponent(): void {
    if (this.topComponentHost) {
      this.topComponentHost.clear();
      this.topComponentRef = this.topComponentHost.createComponent(BikeCardComponent);
      this.topComponentRef.instance.bikeId = this.itemId;
      this.topComponentRef.instance.cardstyle = 'small';
    }

  }

  getSelectFieldOptionsList(field: string): Array<{ value: string, label: string }> {
    if (field in this.selectorFieldsOptions && this.selectorFieldsOptions[field] != null) {
      return this.selectorFieldsOptions[field];
    }

    switch (field) {
      case 'rentalTariff':
        this.selectorFieldsOptions[field] = [];
        this.rentalsTariffsService.getList({}).subscribe((tariffs: RentalTariff[]) => {
          if (this.selectorFieldsOptions[field].length > 0) {
            return this.selectorFieldsOptions[field];
          }
          for (const t of tariffs) {
            this.selectorFieldsOptions[field].push({value: t.id, label: t.name});
            }
        });
        return this.selectorFieldsOptions[field];
      case 'type':
        if (this.bikeTypesSimpleList) {
          return this.bikeTypesSimpleList;
        }
        const groups = this.usersService.getCustomConfig().get_bike_types();
        // TODO: add support for groups, as in the stepper
        this.bikeTypesSimpleList = [];
        for (const group of groups) {
          // tslint:disable-next-line:no-string-literal
          for (const type of group['bikes']) {
            this.bikeTypesSimpleList.push({value: type.value, label: type.I18N_KEY});
          }
        }
        return this.bikeTypesSimpleList;
      default:
        return [];
    }
  }

  loadRelatedComponent(): void {
    // we can abstract the takeuntil ondestroy to the abstract parent class
    // Load list of bikes and bss for the client
    // TODO: if its supplier load list of filtered products
    if (!this.item$) {
      return;
    }
    this.item$.pipe(takeUntil(this.onDestroy$)).subscribe((i) => {
      if (!this.relatedComponentHost) {
        return;
      }
      this.relatedComponentHost.clear();
      this.relatedComponentRef = this.relatedComponentHost.createComponent(TabsUserRelatedComponentsComponent);
      // this.relatedComponentRef = this.viewContainerRef.createComponent(TabsUserRelatedComponentsComponent);
      // TODO: Setting params for the component once is created causes ExpressionChangedAfterItHasBeenCheckedError
      //  on debug mode, we should improve this. maybe with a setter?

      this.relatedComponentRef.instance.tabComponents = [
        {i18n_label: 'SERVICE_SHEETS', component: ServicesheetListComponent, params: {minimal: true, parentId: i.id, parentApiFilter: 'bike__id', defaultFilters: {closed: ''}}},
        {i18n_label: 'TASKS', component: BssTaskListComponent, params: {parent: i, parentType: 'bike', mode: 'viewList'}},
        {i18n_label: 'INSTALLED_COMPONENTS', component: BikesInstalledProductsListComponent, params: {bikeId: i.id}},
      ];
    });
  }

  printLabelItem(bike: Bike): void {
    window.open(`${environment.apiUrl}/bikes/print/labels?bike_id=${bike.id}&print=1`, '_blank');
  }

}
