import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Product} from 'src/app/models/pos_product';
import {PosProductService} from 'src/app/services/pos-products.service';
import {TitleService} from 'src/app/services/title.service';
import {
  DisplayedColumnMetaData,
  DisplayedColumnTypes,
  FilterMetaData,
  FilterOption,
  FilterTypes,
  ItemSimpleFiltrableListAbstractComponent
} from '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component';
import {Fab, FabAction, FabTypes} from '../../components/fab-custom/fab-interface';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {
  SimpleTextDialogComponent
} from '../../components/dialogs/simple-text-dialog-component/simple-text-dialog.component';
import {CrmNotificationsService} from '../../services/crm-notifications.service';
import {ProgressDialogComponent} from '../../components/dialogs/progress-dialog-component/progress-dialog.component';
import {PosProductsDetailComponent} from '../pos-products-detail/pos-products-detail.component';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {UsersService} from '../../services/users.service';
import {PrivacyModeService} from '../../services/privacy-mode.service';
import {PosBrandService} from '../../services/pos-brand.service';
import {PosCategoryProductService} from '../../services/pos-category-product.service';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';


@Component({
  selector: 'app-pos-products-list',
  templateUrl: '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.html',
  styleUrls: ['../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.scss']
})
export class PosProductsListComponent extends ItemSimpleFiltrableListAbstractComponent<Product>
  implements OnInit, OnDestroy, AfterViewInit {

  pageTitleI18N = 'PRODUCTS';

  urlBaseDetailItem = 'pos/products';

  autoFocusSearchBox = true;

  displayedColumnsMetaData = {
    picture: new DisplayedColumnMetaData('', DisplayedColumnTypes.picture, true, true),
    name: new DisplayedColumnMetaData('NAME', DisplayedColumnTypes.text, true, true),
    brandName: new DisplayedColumnMetaData('BRAND', DisplayedColumnTypes.text, true, false),
    categoryName: new DisplayedColumnMetaData('CATEGORY', DisplayedColumnTypes.text, true, false),
    code: new DisplayedColumnMetaData('CODE', DisplayedColumnTypes.text, true, false),
    stock: new DisplayedColumnMetaData('STOCK', DisplayedColumnTypes.text, true, true),
    priceWithVat: new DisplayedColumnMetaData('PVP', DisplayedColumnTypes.currency, true, true),
  };


  primaryFab = new Fab('NEW_PRODUCT', 'add', 'fab_add_new_product', FabTypes.multipleAction, [
    new FabAction('ADD_MANUALLY', '', 'fab_add_product_manually'),
    new FabAction('ADD_BY_CODE', '', 'fab_add_product_by_code')
  ]);
  secondaryFab = null;

  filtersMetaData = {
    workshop_id: new FilterMetaData('SHOP', FilterTypes.select_single, [new FilterOption('default_null_filter', 'LOADING_AND_THREE_DOTS')]),
    catalog_status: new FilterMetaData(
      'CATALOG_STATUS',
      FilterTypes.select_single,
      [
        new FilterOption('mainc', 'MAIN_CATALOG'),
        new FilterOption('ondem', 'ON_DEMAND_CATALOG'),
        new FilterOption('disco', 'DISCONTINUED_CATALOG'),
        // new FilterOption('thisisalist,mainc,ondem', 'AVAILABLE_CATALOG'),
        new FilterOption('default_null_filter', 'ALL'),
      ]),
    supplier: new FilterMetaData('SUPPLIER', FilterTypes.select_single, [new FilterOption('default_null_filter', 'LOADING_AND_THREE_DOTS')]),
    brand: new FilterMetaData('BRAND', FilterTypes.select_single, [new FilterOption('default_null_filter', 'LOADING_AND_THREE_DOTS')]),
    category: new FilterMetaData('CATEGORY', FilterTypes.select_single, [new FilterOption('default_null_filter', 'LOADING_AND_THREE_DOTS')])
  };
  apiFilters = {};

  constructor(
    @Optional() public dialogRef: MatDialogRef<PosProductsListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string },
    protected router: Router,
    protected titleService: TitleService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected posProductService: PosProductService,
    protected posBrandService: PosBrandService,
    protected posCategoryProductService: PosCategoryProductService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected dialog: MatDialog,
    protected notificationService: CrmNotificationsService,
    protected media$: MediaObserver,
    protected usersService: UsersService,
    protected privacyModeService: PrivacyModeService,
    protected hotkeysService: HotkeysService,
    protected userService: UsersService
  ) {
    super(
      dialogRef,
      data,
      router,
      titleService,
      activatedRoute,
      formBuilder,
      posProductService,
      nativeInterfacesService,
      dialog,
      media$,
      usersService,
      privacyModeService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (this.dialogRef != null) {
      if (this.data.mode === 'floating-selector') {
      }
    }

    if (this.nativeInterfacesService.hasCamera) {
      this.secondaryFab = new Fab('', 'qr_code_scanner', 'fab_add_by_cam_code_scanner', FabTypes.singleAction);
    }

    if (this.userService.userMe && this.userService.userMe.workshop) {
      // TODO: this shluld not happen, we should wait for the user to be loaded (maybe on a lodcing screen?)
      // tslint:disable-next-line
      this.defaultFilters['workshop_id'] = this.userService.userMe.workshop;
    }

    this.populateShopFilter();
    this.populateSupplierFilter();
    this.populateBrandFilter();
    this.populateCategoryFilter();
  }

  populateSupplierFilter(): void {
    if (!this.filtersMetaData.hasOwnProperty('supplier')) {
      return;
    }
    this.usersService.getSuppliers().subscribe(suppliers => {
      const filterOptions = [new FilterOption('default_null_filter', 'ALL')];
      for (const supplier of suppliers) {
        filterOptions.push(new FilterOption(supplier.id, supplier.name));
      }
      // tslint:disable-next-line:no-string-literal
      this.filtersMetaData['supplier'] = new FilterMetaData('SUPPLIER', FilterTypes.select_single, filterOptions);
    });
  }

  populateBrandFilter(): void {
    if (!this.filtersMetaData.hasOwnProperty('brand')) {
      return;
    }
    this.posBrandService.getList({}, '', false).subscribe(brands => {
      const filterOptions = [new FilterOption('default_null_filter', 'ALL')];
      for (const brand of brands) {
        filterOptions.push(new FilterOption(brand.id, brand.name));
      }
      // tslint:disable-next-line:no-string-literal
      this.filtersMetaData['brand'] = new FilterMetaData('BRAND', FilterTypes.select_single, filterOptions);
    });
  }

  populateShopFilter(): void {
    if (!this.filtersMetaData.hasOwnProperty('workshop_id')) {
      return;
    }

    if (this.userService.business.workshopCount === 1) {
      // tslint:disable-next-line
      delete (this.filtersMetaData['workshop_id']);
      return;
    }

    const filterOptions = [new FilterOption('default_null_filter', 'ALL')];
    for (const workshop of this.userService.business.workshops) {
      filterOptions.push(new FilterOption(workshop.id, workshop.name));
    }
    // tslint:disable-next-line
    this.filtersMetaData['workshop_id'] = new FilterMetaData('SHOP', FilterTypes.select_single, filterOptions);
  }

  populateCategoryFilter(): void {
    if (!this.filtersMetaData.hasOwnProperty('category')) {
      return;
    }
    this.posCategoryProductService.getList({}, '', false).subscribe(categories => {
      const filterOptions = [new FilterOption('default_null_filter', 'ALL')];
      for (const category of categories) {
        filterOptions.push(new FilterOption(category.id, category.name));
      }
      // tslint:disable-next-line:no-string-literal
      this.filtersMetaData['category'] = new FilterMetaData('CATEGORY', FilterTypes.select_single, filterOptions);
    });
  }

  addByCodeDialog(): void {
    const dialogRef = this.dialog.open(SimpleTextDialogComponent, {});
    dialogRef.componentInstance.showBarCodeButton = true;
    dialogRef.componentInstance.hintI18N = 'CODE';
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 1) {
        this.addProductByCode(result);
      } else {
        this.notificationService.warningI18N('EMPTY_CODE');
      }
    });
  }

  addProductByCode(code: string): void {
    // TODO: move to a service to deduplicate (posservice)?
    let getByCode$;

    const progressDialogRef = this.dialog.open(ProgressDialogComponent, {});
    progressDialogRef.componentInstance.titleI18nKey = 'SEARCHING_PRODUCT';
    progressDialogRef.componentInstance.messageI18nKey = '';
    progressDialogRef.componentInstance.onCancel.subscribe(result => {
      if (getByCode$ != null) {
        getByCode$.unsubscribe();
      }
    });

    getByCode$ = this.posProductService.getOrCreateByCode(code).subscribe(r => {
      progressDialogRef.close();
      const product = r.item;
      this.router.navigate(['/pos/products/' + product.id], {queryParams: {edit: 'edit'}});
    }, error => {
      progressDialogRef.close();
      this.router.navigate(['/pos/products/create', {code}]);
    });
  }

  createItemManually(): void {
    this.router.navigate(['/pos/products/create']);
  }

  onFabAction(actionId: string): boolean {
    if (actionId === 'fab_add_product_manually') {
      this.createItemManually();
      return true;
    }
    if (actionId === 'fab_add_product_by_code') {
      this.addByCodeDialog();
      return true;
    }
    if (actionId === 'fab_add_by_cam_code_scanner') {
      this.startCamBarcodeScanner();
      return true;
    }
    if (actionId === 'fab_add_new_product') {
      if (this.dialog) {
        const dialogRef = this.dialog.open(PosProductsDetailComponent, {
          maxHeight: '90vh',
          minWidth: '60%',
          maxWidth: '90%',
          panelClass: 'no-padding-dialog-container',
          data: {
            // extraMessageI18nKey: 'PRODUCT_JUST_ADDED_TO_THE_BUSINESS_DB_EDIT_BEFORE_ADDING_TO_THE_SALE',
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          const p: Product = result;
          this.dialogRef.close(p);
        });
      }
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }

  onBarCodeScanner(code: string): void {
    this.addProductByCode(code);
  }

  configureKeyboardShortcuts(): void {
    // Hot Keys:
    // https://github.com/brtnshrdr/angular2-hotkeys
    this.hotkeysService.add(
      new Hotkey(
        'c', // key combination
        (): boolean => { // callback function to execute after key combination
          this.createItemManually();
          return false; // prevent bubbling
        },
        undefined, // allow shortcut execution in these html elements
        'Add product manually' // shortcut name // TODO: translate i18n
      )
    );
  }
}
