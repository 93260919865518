import {AfterViewInit, Component, Inject, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from 'src/app/services/title.service';
import {UsersService} from 'src/app/services/users.service';
import {
  DisplayedColumnMetaData,
  DisplayedColumnTypes,
  ItemSimpleFiltrableListAbstractComponent
} from '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component';
import {Fab, FabTypes} from '../../components/fab-custom/fab-interface';
import {NativeInterfacesService} from '../../services/native-interfaces.service';
import {MediaObserver} from '@ngbracket/ngx-layout';
import {PrivacyModeService} from '../../services/privacy-mode.service';
import {BikesService} from '../../services/bikes.service';
import {Bike} from '../../models/bike';

@Component({
  selector: 'app-bikes-list-v2',
  templateUrl: '../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.html',
  styleUrls: ['../../components/abstract-simple-filtrable-item-list/abstract-simple-filtrable-item-list.component.scss']
})
export class BikesListV2Component extends ItemSimpleFiltrableListAbstractComponent<Bike> implements OnInit, AfterViewInit {
  // TODO: filters like archived or staff, and others should be abstracted

  pageTitleI18N = 'LOADING_AND_THREE_DOTS';

  autoFocusSearchBox = true;

  mode = 'bikes';  // clients, bikes_rental

  urlBaseDetailItem = `/${this.mode}/`;

  displayedColumnsMetaData = {
    mainPictureUrl: new DisplayedColumnMetaData('', DisplayedColumnTypes.picture),
    name: new DisplayedColumnMetaData('NAME'),
    shortPrettyId: new DisplayedColumnMetaData('ID'),
    frameSize: new DisplayedColumnMetaData('FRAME_SIZE'),
    rentalTariffName: new DisplayedColumnMetaData('RENTAL_TARIFF')
  };

  primaryFab = null;
  secondaryFab = null;

  apiFilters = {};

  constructor(
    @Optional() public dialogRef: MatDialogRef<BikesListV2Component>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { mode: string, rentalbikesAvailabilityId: string },
    protected router: Router,
    protected titleService: TitleService,
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: UntypedFormBuilder,
    protected dialog: MatDialog,
    protected bikesService: BikesService,
    protected usersService: UsersService,
    protected nativeInterfacesService: NativeInterfacesService,
    protected media$: MediaObserver,
    protected privacyModeService: PrivacyModeService
  ) {
    super(
      dialogRef,
      data,
      router,
      titleService,
      activatedRoute,
      formBuilder,
      bikesService,
      nativeInterfacesService,
      dialog,
      media$,
      usersService,
      privacyModeService);
  }

  ngOnInit(): void {
    if (this.dialogMode) {
      if (this.data.mode === 'rental-bike-selector') {
        this.mode = 'bikes_rental';
      }
    } else {
      if (this.activatedRoute.snapshot.url[0].path === 'bikes') {
        this.mode = 'bikes';
      } else if (this.activatedRoute.snapshot.url[0].path === 'bikes_rental') {
        this.mode = 'bikes_rental';
      }
    }
    this.urlBaseDetailItem = `/${this.mode}/`;

    this.setFilters();

    super.ngOnInit();
  }

  private setFilters(): void {
    if (this.mode === 'bikes_rental') {
      this.primaryFab = new Fab('NEW_RENTAL_BIKE', 'add', 'new_rental_bike', FabTypes.singleAction);
      this.titleService.setTitleTranslated('RENTAL_BIKES');
      this.pageTitleI18N = 'RENTAL_BIKES';
      this.apiFilters = {
        use_case: 'ren',
      };
      if (this.data?.rentalbikesAvailabilityId) {
        // tslint:disable-next-line
        this.apiFilters['rental_bikes_availability_id'] = this.data.rentalbikesAvailabilityId;
      }
    }
  }

  isRowEnabled(bike: Bike): boolean {
    if (this.data?.rentalbikesAvailabilityId) {
      return bike.isAvailableToRent === 1 || bike.isAvailableToRent === -1;
    }
    return super.isRowEnabled(bike);
  }

  async onFabAction(actionId: string): Promise<boolean> {
    if (actionId === 'new_rental_bike') {
      this.router.navigate([`/${this.urlBaseDetailItem}/create`]);
      return true;
    }
    console.log(`TODO: implement ${actionId}`);
    return true;
  }
}
